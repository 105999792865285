import React from "react";
import '../../styles/semantic.min.css';
import SEO from "../../components/seo/Seo";
import LayoutContainer from "../../container/LayoutContainer";
import PageHeader from "../../components/utils/PageHeader";
import MainNavigation from "../../components/navigation/MainNavigation";
import Footer from "../../components/footer/Footer";
import {Container, Icon} from "semantic-ui-react";
import DefaultBasicButton from "../../components/utils/buttons/DefaultBasicButton";
import MobileTabletContainer from "../../container/MobileTabletContainer";
import DesktopContainer from "../../container/DesktopContainer";
import layout from "../../templates/layout.module.css";
import DarkButton from "../../components/utils/buttons/DarkButton";

export default () => <LayoutContainer>
    <SEO title={'Danke'}/>
    <MobileTabletContainer>
        <MainNavigation/>
        <Container fluid>
            <Icon name={'check circle'} size={'massive'} color={'green'}
                  style={{margin: '0 auto', display: 'block', paddingTop: '3rem'}}/>
            <PageHeader title={'Vielen Dank für Ihre Nachricht'}/>
            <Container style={{textAlign:'center', marginBottom: '3em'}}>
                <DefaultBasicButton link={'/'}><Icon name={'home'}/> Zurück zur Startseite</DefaultBasicButton>
            </Container>
        </Container>
        <Footer/>
    </MobileTabletContainer>
    <DesktopContainer>
        <div className={layout.pageWrapper}>
        <MainNavigation/>
        <Container fluid>
            <Icon name={'check circle'} size={'massive'} color={'green'}
                  style={{margin: '0 auto', display: 'block', paddingTop: '3rem'}}/>
            <PageHeader title={'Vielen Dank für Ihre Nachricht'}/>
            <Container style={{textAlign:'center', marginBottom: '3em'}}>
                <DarkButton link={'/'}><Icon name={'home'}/> Zurück zur Startseite</DarkButton>
            </Container>
        </Container>
        <Footer/>
        </div>
    </DesktopContainer>

</LayoutContainer>